import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const colors = {
  red: '#FF9060',
  redSecondary: '#F24900',
  redAccent: '#FF5959',
  brown: '#9C6729',
  brownSecondary: '#877E75',
  yellow: '#F4DC8A',
  yellowSecondary: '#E9BF35',
  blue: '#6BEDFF',
  blueSecondary: '#79CBD7',
  green: '#9CD580',
  greenSecondary: '#75AD59',
  mint: '#EEF5E5',
  mintSecondary: '#DCE4D1',
  silver: '#F8F8F8',
  silverSecondary: '#ECECEC',
  greyLight: '#BABABA',
  grey: '#979797',
  greySecondary: '#787878',
  black: '#4B4F54',
  blackSecondary: '#000000',
};

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...colors,
        primary: colors.black,
        secondary: colors.greenSecondary,
        accent: colors.green,
        info: colors.blue,
        success: colors.green,
        warning: colors.yellow,
        error: colors.red,
      },
    },
    options: {
      customProperties: true,
    },
    icons: {
      iconfont: 'fa',
    },
  },
});
