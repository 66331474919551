export const loginRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/modules/Login/views/LoginView.vue'),
    meta: {
      title: 'login.views.loginView.title',
      auth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '@/modules/Login/views/ForgotPassword.vue'),
    meta: {
      title: 'login.views.forgotPassword.title',
      auth: false,
    },
  },
  {
    path: '/replace-forgotten-password',
    name: 'replace-forgotten-password',
    component: () =>
      import(/* webpackChunkName: "replace-forgotten-password" */ '@/modules/Login/views/ReplaceForgottenPassword.vue'),
    meta: {
      title: 'login.views.replaceForgottenPassword.title',
      auth: false,
    },
  },
  {
    path: '/activate-account',
    name: 'activate-account',
    component: () => import(/* webpackChunkName: "activate-account" */ '@/modules/Login/views/ActivateAccount.vue'),
    meta: {
      title: 'login.views.activateAccount.title',
      auth: false,
    },
  },
  {
    path: '/request-activation-email',
    name: 'request-activation-email',
    component: () =>
      import(/* webpackChunkName: "request-activation-email" */ '@/modules/Login/views/RequestActivationEmail.vue'),
    meta: {
      title: 'login.views.requestActivationEmail.title',
      auth: false,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import(/* webpackChunkName: "verify-email" */ '@/modules/Login/views/VerifyEmail.vue'),
    meta: {
      title: 'login.views.verifyEmail.title',
    },
  },
  {
    path: '/impersonate',
    name: 'impersonate',
    component: () => import(/* webpackChunkName: "impersonate" */ '@/modules/Login/views/ImpersonateView.vue'),
    meta: {
      title: 'login.views.impersonate.title',
    },
  },
  {
    path: '/device-selection',
    name: 'device-selection',
    component: () => import(/* webpackChunkName: "device-selection" */ '@/modules/Login/views/DeviceSelection.vue'),
    meta: {
      title: 'login.views.deviceSelection.title',
      auth: true,
      usePublicLayout: true,
    },
  },
];
