<template>
  <v-app-bar color="transparent" elevation="0" fixed>
    <slot name="left" />
    <v-spacer />
    <div class="title text-overline font-weight-medium text-truncate text-uppercase grey--text px-3">
      {{ $t(title) }}
    </div>
    <v-spacer />
    <slot name="right" />
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  z-index: 6;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
    transition-property: transform, background-color, left, right, box-shadow, max-width, width, border-bottom-color;
    transition-duration: 0.2s;

    &--is-scrolled {
      border-bottom-color: rgba(0, 0, 0, 0.1) !important;
      background-color: white !important;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .v-app-bar {
    .title {
      transition-property: opacity;
      transition-duration: 0.2s;
    }

    &--is-scrolled {
      .title {
        opacity: 0;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .v-app-bar {
    margin-left: 56px;
  }
}
</style>
