export const LOGIN_NAMESPACE = 'LOGIN_NAMESPACE';

export const configureLoginStore = (Vue) => {
  return {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      async forgotPassword(_, emailAddress) {
        try {
          await Vue.prototype.$http.post('/command/user-management/user/forgot-password', {
            emailAddress,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async requestActivationEmail(_, emailAddress) {
        try {
          await Vue.prototype.$http.post('/command/user-management/user/request-activation-mail', {
            emailAddress,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async updateForgotPassword(_, { hash, plainPassword }) {
        try {
          await Vue.prototype.$http.post('/command/user-management/user/update-forgotten-password', {
            hash,
            plainPassword,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async activateAccount(_, { hash, plainPassword }) {
        try {
          await Vue.prototype.$http.post('/command/user-management/user/activate', {
            hash,
            plainPassword,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async verifyEmail(_, { hash }) {
        try {
          await Vue.prototype.$http.post('/command/user-management/user/verify-email', {
            hash,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
    },
    getters: {},
  };
};
