import { LAYOUT_NAMESPACE } from '@/modules/Layout/stores';

export const DASHBOARD_NAMESPACE = 'DASHBOARD_NAMESPACE';

export const configureDashboardStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      realtimeData: {
        offline: false,
        lastActivity: null,
        heating: null,
        hotWater: null,
        activeOptions: 0,
        batteryPower: 0,
        batteryStateOfCharge: 0,
        batteryStatus: 0,
        difference: 0,
        totalConsumption: 0,
        totalGeneration: 0,
        household: 0,
        wallBoxChargeState: 0,
        wallBoxPower: 0,
        wallBoxManufacturer: 0,
        relaisStatus: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
        },
      },
      dashboardData: {
        totalGenerationMonthly: 0,
        totalGenerationYearly: 0,
        relaisNames: {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          7: '',
          8: '',
        },
      },
    },
    mutations: {
      setRealtimeData(state, payload) {
        state.realtimeData = payload;
      },
      setDashboardData(state, payload) {
        state.dashboardData = payload;
      },
    },
    actions: {
      async fetchRealtimeData({ rootState, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const response = await Vue.prototype.$http.get('/customer-ui/dashboard/real-time-data', {
          params: {
            sunbrainId,
          },
        });
        if (sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId) {
          commit('setRealtimeData', {
            offline: response.data.offline,
            lastActivity: response.data.lastActivity,
            heating: !isNaN(parseFloat(response.data.data?.heating)) ? parseFloat(response.data.data?.heating) : null,
            hotWater: !isNaN(parseFloat(response.data.data?.hotWater))
              ? parseFloat(response.data.data?.hotWater)
              : null,
            activeOptions: parseInt(response.data.data?.activeOptions || 0),
            batteryPower:
              0 !== parseFloat(response.data.data?.batteryPower || 0)
                ? parseFloat(response.data.data?.batteryPower || 0) * -1
                : 0,
            batteryStateOfCharge: parseInt(response.data.data?.batteryStateOfCharge || 0),
            batteryStatus: parseInt(response.data.data?.batteryStatus || 0),
            difference:
              parseFloat(response.data.data?.totalGeneration || 0) -
              parseFloat(response.data.data?.totalConsumption || 0),
            totalConsumption: parseFloat(response.data.data?.totalConsumption || 0),
            totalGeneration: parseFloat(response.data.data?.totalGeneration || 0),
            household: parseFloat(response.data.data?.household || 0),
            wallBoxChargeState: parseInt(response.data.data?.wallBoxChargeState || 0),
            wallBoxPower: parseFloat(response.data.data?.wallBoxPower || 0),
            wallBoxManufacturer: parseInt(response.data.data?.wallBoxManufacturer || 0),
            relaisStatus: {
              1: 1 === parseInt(response.data.data?.relais0Status || 0),
              2: 1 === parseInt(response.data.data?.relais1Status || 0),
              3: 1 === parseInt(response.data.data?.relais2Status || 0),
              4: 1 === parseInt(response.data.data?.relais3Status || 0),
              5: 1 === parseInt(response.data.data?.relais4Status || 0),
              6: 1 === parseInt(response.data.data?.relais5Status || 0),
              7: 1 === parseInt(response.data.data?.relais6Status || 0),
              8: 1 === parseInt(response.data.data?.relais7Status || 0),
            },
          });
        }
      },
      async fetchDashboardData({ rootState, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const response = await Vue.prototype.$http.get('/customer-ui/dashboard/data', {
          params: {
            sunbrainId,
          },
        });
        if (sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId) {
          commit('setDashboardData', {
            totalGenerationMonthly: parseInt(response.data.data?.monthly?.totalGeneration || 0),
            totalGenerationYearly: parseInt(response.data.data?.yearly?.totalGeneration || 0),
            relaisNames: {
              1: response.data.portNames?.relais1?.displayName || '',
              2: response.data.portNames?.relais2?.displayName || '',
              3: response.data.portNames?.relais3?.displayName || '',
              4: response.data.portNames?.relais4?.displayName || '',
              5: response.data.portNames?.relais5?.displayName || '',
              6: response.data.portNames?.relais6?.displayName || '',
              7: response.data.portNames?.relais7?.displayName || '',
              8: response.data.portNames?.relais8?.displayName || '',
            },
          });
        }
      },
    },
    getters: {},
  };
};
