export const dashboardTranslations = {
  de: {
    dashboard: {
      headline: 'Übersicht',
      views: {
        dashboardView: {
          title: 'Übersicht',
          flow: 'Stromfluss',
          optimize: 'Optimieren Sie Ihren Gas-, Strom-<br />und Wärmeverbrauch mit Sunbrain.',
          generation: 'Erzeugung',
          consumption: 'Verbrauch',
          household: 'Haushalt',
          gridFeed: 'Einspeisung',
          powerSupply: 'Netzbezug',
          battery: 'Batterie',
          batteryStatus: {
            0: 'n/a',
            1: 'Pausiert',
            2: 'Lädt',
            3: 'Entlädt',
            offline: 'Offline',
          },
          alert: {
            offline: {
              title: 'Sunbrain ist offline.',
              message:
                'Die aktuellen Daten können derzeit nicht angezeigt werden.<br />Bitte stellen Sie sicher, dass das Gerät ordnungsgemäß mit dem Internet verbunden ist.',
            },
          },
        },
      },
      components: {
        heatingBox: {
          title: 'Smart heizen',
          title2Line: 'Smart<br />heizen',
          subtitle: 'Heizung',
          teaser: 'Nachhaltige Wärme und Effizienz: Entdecken Sie die Vorteile moderner Wärmepumpen für Ihr Zuhause.',
          showMore: 'Mehr erfahren',
        },
        hotWaterBox: {
          title: 'Effizientes Warmwasser',
          title2Line: 'Effiziente<br />Wärme',
          subtitle: 'Warmwasser',
          teaser: 'Nutzen Sie Ihre Energie optimal – für nachhaltiges und kostensparendes Warmwasser!',
          showMore: 'Mehr erfahren',
        },
        batteryBox: {
          title: 'Energiespeicher',
          title2Line: 'Smart<br />speichern',
          teaser:
            'Speichern Sie überschüssigen Solarstrom mit einer Batterie und nutzen Sie Ihre Energie genau dann, wenn Sie sie brauchen!',
          showMore: 'Mehr erfahren',
        },
        carBox: {
          title: 'sunbrain.car',
          title2Line: 'sunbrain.<br />car',
          car: 'Auto',
          carStatus: {
            0: 'Fehler',
            1: 'n/a',
            2: 'Nicht verbunden',
            3: 'Nicht verbunden',
            4: 'Gestoppt',
            5: 'Gestoppt',
            6: 'Ladebereit',
            7: 'Lädt',
            offline: 'Offline',
          },
          wallbox: 'Wallbox',
          wallboxStatus: {
            0: 'Fehler',
            1: 'n/a',
            2: 'Gestoppt',
            3: 'Ladebereit',
            4: 'Gestoppt',
            5: 'Ladebereit',
            6: 'Gestoppt',
            7: 'Lädt',
            offline: 'Offline',
          },
          teaser:
            'Mit Sunbrain Ihre Photovoltaikanlage intelligent nutzen und Ihr E-Auto nachhaltig aufladen – direkt von der Sonne!',
          showMore: 'Mehr erfahren',
        },
        deviceBox: {
          title: 'Aktive Geräte',
          phase: 'sunbrain.car Phase',
          relais: 'Anschluss',
          offline:
            'Die Geräteliste kann nur abgerufen werden, wenn der Sunbrain über eine aktive Internetverbindung verfügt.',
          noDevices: 'Es stehen keine Geräteanschlüsse zur Verfügung.',
        },
        mainPartnerBox: {
          title: 'Betreut durch',
        },
        generatingData: {
          title: 'Erzeugung',
          monthly: 'Diesen Monat',
          yearly: 'Dieses Jahr',
        },
      },
    },
  },
};
